import { useState, useEffect } from "react";
import { Navigation } from "./components/Navigation";
import { Header } from "./components/Header";
import { Features } from "./components/Features";
import { About } from "./components/About";
import { Services } from "./components/Services";
import { Gallery } from "./components/Gallery";
import { Testimonials } from "./components/Testimonials";
import { Team } from "./components/Team.js";
import { Contact } from "./components/Contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import { Events } from "./components/Events";
import { Valunteer } from "./components/Valunteer";
import { Donate } from "./components/Donate";

export const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 1000,
    speedAsDuration: true,
});

const App = () => {
    const [landingPageData, setLandingPageData] = useState({});
    useEffect(() => {
        setLandingPageData(JsonData);
    }, []);

    return (
        <div>
            <Navigation />
            <Header data={landingPageData.Header} />
            <br /><br /><br />
            <Features data={landingPageData.Features} />
            <About data={landingPageData.About} />
            <Events data={landingPageData.Events}/>
            {/*<Services data={landingPageData.Services} />*/}
            <Gallery data={landingPageData.Gallery} />
            
            <Donate />
            {/*<Testimonials data={landingPageData.Testimonials} />*/}
            {/*<Team data={landingPageData.Team} />*/}
            <br /><br />
            <Contact data={landingPageData.Contact} />
        </div>
    );
};

export default App;
