export const Features = (props) => {
    return (
        <div id='features' className='text-center'>
            <div className='container'>
                <div className='col-md-10 col-md-offset-1 section-title'>
                    <h2>Features</h2>
                </div>
                <div className='row'>
                    {props.data
                        ? props.data.map((d, i) => (
                            <div key={`${d.title}-${i}`} className='col-xs-6 col-md-3'>
                                {' '}
                                <i className={d.icon}></i>
                                <h2 style={{ color: "#777" }}>{d.count}</h2>
                                <h3 style={{ color: "#777" }}>{d.title}</h3>                                
                            </div>
                        ))
                        : 'Loading...'}
                </div>
            </div>
        </div>
    )
}
