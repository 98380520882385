import { useState } from 'react'

import axios from 'axios';

const initialState = {
    name: '',
    email: '',
    mobile: '',
    location: '',
    message: '',
}
export const Contact = (props) => {
    const apiPath = "https://missionmilliontrees.in/api/app/sendmessage";
    //const apiPath = "https://app-missionmilliontrees-dev.azurewebsites.net/api/app/sendmessage"
    //const apiPath = "https://localhost:7069/api/app/sendmessage";

    const [{ name, email, mobile, location, message }, setState] = useState(initialState)

    const handleChange = (e) => {
        const { name, value } = e.target
        setState((prevState) => ({ ...prevState, [name]: value }))
    }
    const clearState = () => setState({ ...initialState })

    const [sending, setSending] = useState(false);
    const [errorMsg, setErrorMsg] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false)
    const [validationMsg, setValidationMsg] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault()
        console.log(name, email, message);
        setValidationMsg("");
        setSending(true);

        try {
            let addMsgValues = {
                "name": name,
                "mobile": mobile,
                "email": email,
                "location": location,
                "message": message
            }
            axios.post(apiPath, addMsgValues)
                .then(response => {
                    if (response.status === 200) {
                        setErrorMsg(false);
                        setSuccessMsg(true);
                    }
                    setSending(false);
                })
                .catch(error => {
                    console.log(error);
                    setErrorMsg(true);
                    setSuccessMsg(false);
                    setSending(false);
                });
        }
        catch (e) {
            setErrorMsg(true);
            setSuccessMsg(false);
            setSending(false);
        }
    }

    return (
        <div>
            <div id='contact' style={{ paddingTop: "0px" }}>
                <div className='container'>
                    <div className='col-md-8'>
                        <div className='row'>
                            <div className='section-title'>
                                <h2>WANTS TO BECOME A VOLUNTEER</h2>
                                <p>
                                    Please fill out the form below to send us an email and we will
                                    get back to you as soon as possible.
                                </p>
                            </div>
                            <form name='sentMessage' validate onSubmit={handleSubmit}>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className='form-group'>
                                            <input
                                                type='text'
                                                id='name'
                                                name='name'
                                                className='form-control'
                                                placeholder='Name'
                                                required
                                                onChange={handleChange}
                                            />
                                            <p className='help-block text-danger'></p>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='form-group'>
                                            <input
                                                type='email'
                                                id='email'
                                                name='email'
                                                className='form-control'
                                                placeholder='Email'
                                                required
                                                onChange={handleChange}
                                            />
                                            <p className='help-block text-danger'></p>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className='form-group'>
                                            <input
                                                type='text'
                                                id='mobile'
                                                name='mobile'
                                                className='form-control'
                                                placeholder='Mobile'
                                                required
                                                onChange={handleChange}
                                            />
                                            <p className='help-block text-danger'></p>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='form-group'>
                                            <input
                                                type='text'
                                                id='location'
                                                name='location'
                                                className='form-control'
                                                placeholder='Location'
                                                required
                                                onChange={handleChange}
                                            />
                                            <p className='help-block text-danger'></p>
                                        </div>
                                    </div>
                                </div>
                                <div className='form-group'>
                                    <textarea
                                        name='message'
                                        id='message'
                                        className='form-control'
                                        rows='4'
                                        placeholder='Message'
                                        required
                                        onChange={handleChange}
                                    ></textarea>
                                    <p className='help-block text-danger'></p>
                                </div>
                                <div id='msgs' className="contact">
                                    {(sending) ?
                                        <div className="loading">Sending...</div> : null}
                                    {(successMsg) ?
                                        <div className="sent-message">Your message has been sent. Thank you!</div> : null}
                                    {(errorMsg) ?
                                        <div className="error-message">Something went wrong. please try again later !!</div> : null}
                                </div>
                                <button type='submit' className='btn btn-custom btn-lg'>
                                    Send Joining Request
                                </button>
                            </form>
                        </div>
                    </div>
                    <div className='col-md-3 col-md-offset-1 contact-info'>
                        <div className='contact-item'>
                            <h3>Contact Info</h3>
                            <p>
                                <span>
                                    <i className='fa fa-map-marker'></i> Address
                                </span>
                                {props.data ? props.data.address : 'loading'}
                            </p>
                        </div>
                        <div className='contact-item'>
                            <p>
                                <span>
                                    <i className='fa fa-phone'></i> Phone
                                </span>{' '}
                                {props.data ? props.data.phone : 'loading'}
                            </p>
                        </div>
                        <div className='contact-item'>
                            <p>
                                <span>
                                    <i className='fa fa-envelope-o'></i> Email
                                </span>{' '}
                                {props.data ? props.data.email : 'loading'}
                            </p>
                        </div>
                    </div>
                    {/*<div className='col-md-12'>*/}
                    {/*    <div className='row'>*/}
                    {/*        <div className='social'>*/}
                    {/*            <ul>*/}
                    {/*                <li>*/}
                    {/*                    <a href={props.data ? props.data.facebook : '/'}>*/}
                    {/*                        <i className='fa fa-facebook'></i>*/}
                    {/*                    </a>*/}
                    {/*                </li>*/}
                    {/*                <li>*/}
                    {/*                    <a href={props.data ? props.data.twitter : '/'}>*/}
                    {/*                        <i className='fa fa-twitter'></i>*/}
                    {/*                    </a>*/}
                    {/*                </li>*/}
                    {/*                <li>*/}
                    {/*                    <a href={props.data ? props.data.youtube : '/'}>*/}
                    {/*                        <i className='fa fa-youtube'></i>*/}
                    {/*                    </a>*/}
                    {/*                </li>*/}
                    {/*            </ul>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
            <div id='footer'>
                <div className='container text-center'>
                    <p>
                        <img src="img/tree-mmt.png" style={{ width: "10%" }} alt="" />
                        <br />
                        <span style={{ fontWeight: "600" }}> &copy; 2022 Missionmilliontrees </span>
                        <br /><br />
                        <span>Designed with <i class="fa fa-heart" aria-hidden="true" style={{ color: "red" }}></i> by <a href="https://nsagar.com" target='_blank'>Sagar</a></span>
                    </p>
                </div>
            </div>
        </div>
    )
}
