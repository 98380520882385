export const Donate = (props) => {
    return (
        <div id='donate' className='text-center'>
            <div className='container'>
                <div className='section-title'>
                    <h2>Donate</h2>
                    <p>
                        As we are an unregistered non-profit organisation but we promise you each and every rupee donated by you will be utilised for planting trees only. Kindly contact us to support our cause.
                        <br />
                        <b> &#8377;100 ~ ONE TREE ~ ONE PLANET.</b>
                    </p>
                </div>
                <div id='row'>
                    <div className='col-md-12 col-sm-12 team'>
                        <img src="img/donate-qr.jpeg" alt="" />
                        <div className='caption'>
                            <div align="center">
                                <h4 style={{ color: "#777" }} className='contact-item'><b>Name :</b> N SANTOSH CHOUDHURY</h4>
                                <h4 style={{ color: "#777" }} className='contact-item'><b>Account Number:</b> 026010100099250</h4>
                                <h4 style={{ color: "#777" }} className='contact-item'><b>IFSC Code:</b> UBIN0802603</h4>
                                <h4 style={{ color: "#777" }} className='contact-item'><b>BANK :</b> UNION BANK OF INDIA JEYPORE BRANCH</h4>
                                <h4 style={{ color: "#777" }} className='contact-item'><b>UPI ID :</b>  missionmilliontrees@axl</h4>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}
