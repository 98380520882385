//import logo from '../../public/img/logo-mmt-min.png'

export const Navigation = (props) => {
    return (
        <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
            <div className='container'>
                <div className='navbar-header'>
                    <button
                        type='button'
                        className='navbar-toggle collapsed'
                        data-toggle='collapse'
                        data-target='#bs-example-navbar-collapse-1'
                    >
                        {' '}
                        <span className='sr-only'>Toggle navigation</span>{' '}
                        <span className='icon-bar'></span>{' '}
                        <span className='icon-bar'></span>{' '}
                        <span className='icon-bar'></span>{' '}
                    </button>
                    <a className='navbar-brand page-scroll' href='#page-top' style={{ color: "#43a906", padding: "0" }}>
                        <img src="img/logo-mmt.png" alt="Missionmilliontrees" style={{ width: "70%" }} />
                    </a>{' '}
                </div>

                <div
                    className='collapse navbar-collapse'
                    id='bs-example-navbar-collapse-1'
                >
                    <ul className='nav navbar-nav navbar-right'>
                        <li>
                            <a href='#features' className='page-scroll'>
                                Features
                            </a>
                        </li>
                        <li>
                            <a href='#about' className='page-scroll'>
                                About
                            </a>
                        </li>
                        <li>
                            <a href='#events' className='page-scroll'>
                                Events
                            </a>
                        </li>
                        {/*<li>*/}
                        {/*  <a href='#services' className='page-scroll'>*/}
                        {/*    Services*/}
                        {/*  </a>*/}
                        {/*</li>*/}
                        <li>
                            <a href='#portfolio' className='page-scroll'>
                                Gallery
                            </a>
                        </li>
                        <li>
                            <a href='#donate' className='page-scroll'>
                                Donate
                            </a>
                        </li>
                        {/*<li>*/}
                        {/*  <a href='#testimonials' className='page-scroll'>*/}
                        {/*    Testimonials*/}
                        {/*  </a>*/}
                        {/*</li>*/}
                        {/*<li>*/}
                        {/*    <a href='#team' className='page-scroll'>*/}
                        {/*        Team*/}
                        {/*    </a>*/}
                        {/*</li>*/}

                        <li>
                            <a href='#contact' className='page-scroll'>
                                Contact
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}
